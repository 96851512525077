import useViewportSize from "@hooks/useViewportSize";
import { track } from "@lib/util/trackAnalytics";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Buttons from "./components/Buttons";
import Footer from "./components/Footer";
import LandscapeHeading from "./components/LandscapeHeading";
import LandscapeHeadings from "./components/LandscapeHeadings";
import LanguageMenu from "./components/LanguageMenu";
import PortaitHeading from "./components/PortraitHeading";
import useLandingRebrandSrcSet from "./hooks/useLandingRebrandSrcSet";
import { useLandingRebrandCopyVariant, useLandingRebrandMediaVariant } from "./hooks/useLandingRebrandVariant";
import useSplitHeading from "./hooks/useSplitHeading";

export default function Landing() {
	const viewport = useViewportSize();
	const isSplitHeading = useSplitHeading();
	const isPortrait = viewport === "mobile" || viewport === "tablet_portrait";
	const mediaVariant = useLandingRebrandMediaVariant();
	const { src, srcSet } = useLandingRebrandSrcSet(mediaVariant);
	const copyVariant = useLandingRebrandCopyVariant();

	useEffect(() => {
		track("landing_page_visited", { source: "landing" });
	}, []);

	return (
		<Box
			component="main"
			sx={(theme) => ({
				position: "relative",
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				backgroundColor: "var(--scruff-red)",
				backgroundSize: "fit",
				backgroundPosition: "center",
				pt: theme.spacing(7.5),
				pb: theme.spacing(2.5),
				px: theme.spacing(1.5),
			})}
		>
			<Box
				sx={(theme) => ({
					zIndex: 1,
					width: "100%",
					display: "flex",
					justifyContent: isPortrait ? "space-between" : "flex-end",
					">button": {
						mt: isPortrait ? "10px" : 0,
						mr: isPortrait ? 0 : theme.spacing(4),
					},
				})}
			>
				{isPortrait ? <PortaitHeading variant={copyVariant} /> : null}
				<LanguageMenu />
			</Box>
			<Box
				sx={(theme) => ({
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					position: "absolute",
					inset: theme.spacing(1),
				})}
			>
				<Box
					component="img"
					srcSet={srcSet}
					src={src}
					alt=""
					sx={{
						objectFit: "contain",
						overflow: "hidden",
						maxWidth: isPortrait ? "100%" : "50%",
						maxHeight: isPortrait ? "75%" : "80%",
					}}
				/>
			</Box>
			{isPortrait ? null : isSplitHeading ? (
				<LandscapeHeadings variant={copyVariant} />
			) : (
				<LandscapeHeading variant={copyVariant} />
			)}
			<Box sx={{ zIndex: 1 }}>
				<Buttons copyVariant={copyVariant} mediaVariant={mediaVariant} />
				<Footer />
			</Box>
		</Box>
	);
}
