// tested with features/landing/index.test.tsx
import { track } from "@lib/util/trackAnalytics";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "secondary",
})<ButtonProps & { secondary?: boolean }>(({ theme, secondary = false }) => ({
	borderRadius: 0,
	fontFamily: "var(--heading-font)",
	fontSize: theme.spacing(2),
	height: "100%",
	minWidth: "fit-content",
	maxWidth: theme.spacing(44.25),
	maxHeight: theme.spacing(7),
	color: theme.palette.common.white,
	border: `solid 1px ${theme.palette.common.black}`,
	backgroundColor: secondary ? theme.palette.secondary.main : theme.palette.common.black,
	"&.MuiButton-root:hover": {
		backgroundColor: secondary ? theme.palette.secondary.light : theme.palette.grey[700],
		border: `solid 1px ${theme.palette.grey[700]}`,
	},
}));

type LandingButtonsProps = {
	copyVariant: string;
	mediaVariant: string;
};
export default function Buttons({ copyVariant, mediaVariant }: LandingButtonsProps) {
	const { t } = useTranslation("common");
	return (
		<>
			<Box
				sx={(theme) => ({
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					px: theme.spacing(2),
					mb: theme.spacing(5),
				})}
			>
				<StyledButton
					secondary
					sx={(theme) => ({ mb: theme.spacing(1.5) })}
					fullWidth
					href="/anon-sign-up"
					LinkComponent={NextLink}
					onClick={() => {
						track("cruise_anonymously_clicked", { source: "landing", copyVariant, mediaVariant });
					}}
				>
					{t("landing.cruise_anonymously")}
				</StyledButton>
				<StyledButton
					fullWidth
					href="/login"
					LinkComponent={NextLink}
					onClick={() => {
						track("log_in_clicked", { source: "landing" });
					}}
				>
					{t("log_in_button")}
				</StyledButton>
			</Box>
		</>
	);
}
