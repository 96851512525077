// tested with features/landing/index.test.tsx
import { styled } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useRef } from "react";
import LandingHeading, { LandingHeadingProps } from "./LandingHeading";
import useFitTextToParent from "./useFitTextToParent";
const StyledLandingHeading = styled(LandingHeading)(({ theme }) => ({
	fontSize: theme.spacing(7.5),
	width: "fit-content",
	maxWidth: "100%",
	visibility: "hidden",
}));

export default function PortaitHeading({ variant }: LandingHeadingProps) {
	const { t } = useTranslation("common");
	const textContent = t(`landing_copy_variants.${variant}`);
	const headingRef = useRef<HTMLHeadingElement>(null);

	useEffect(() => {
		if (headingRef.current == null) {
			return;
		}
		headingRef.current.textContent = textContent;
	}, [textContent]);

	const isResized = useFitTextToParent(headingRef, textContent);

	return (
		<div style={{ width: "70%" }}>
			<StyledLandingHeading ref={headingRef} sx={{ visibility: isResized ? "visible" : "hidden" }} component="h1" />
		</div>
	);
}
