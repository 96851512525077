import useIdify from "@hooks/useIdify";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import i18next from "next-i18next.config.mjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";
const locales = i18next.i18n.locales.slice(1);

export default function LanguageMenu() {
	const { pathname, locale } = useRouter();
	const { t } = useTranslation("common");
	const idify = useIdify();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const sortedLocales = useMemo(
		() => locales.slice().sort((localeA: string) => (localeA === locale ? -1 : 1)),
		[locale],
	);
	const menuButtonLabelId = idify("language-menu-button-label");
	const languageMenuButtonId = idify("language-menu-button");
	const languageMenuId = idify("langauage-menu");
	return (
		<>
			<Button
				variant="contained"
				size="small"
				disableRipple
				ref={buttonRef}
				id={languageMenuButtonId}
				aria-controls={menuOpen ? languageMenuId : undefined}
				aria-haspopup="true"
				aria-expanded={menuOpen ? "true" : undefined}
				aria-describedby={menuButtonLabelId}
				onClick={() => {
					setMenuOpen(true);
				}}
				endIcon={<ExpandMore />}
				sx={(theme) => ({
					"&.MuiButton-contained": {
						backgroundColor: theme.palette.secondary.dark,
						color: "text.primary",
					},
					"&.MuiButton-contained:hover": {
						backgroundColor: theme.palette.secondary.dark,
						backgroundImage: `linear-gradient(${theme.palette.action.disabledBackground},${theme.palette.action.disabledBackground})`,
					},
					position: "static",
					height: "fit-content",
					minWidth: "110px",
					borderRadius: 0,
					boxShadow: "unset",
					zIndex: "tooltip",
				})}
			>
				{t(`languages.${locale}`)}
			</Button>
			<Menu
				id={languageMenuId}
				slotProps={{
					paper: {
						sx: (theme) => ({
							backgroundColor: theme.palette.secondary.dark,
						}),
					},
				}}
				anchorEl={buttonRef.current}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{sortedLocales.map((localeCode) => {
					return (
						<MenuItem
							sx={(theme) => ({
								backgroundColor: theme.palette.secondary.dark,
								"&.Mui-selected": {
									backgroundColor: theme.palette.common.black,
								},
							})}
							selected={localeCode === locale}
							component={Link}
							key={localeCode}
							locale={localeCode}
							href={pathname}
							onClick={() => {
								setMenuOpen(false);
							}}
						>
							{t(`languages_label.${localeCode}`)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
