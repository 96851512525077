import useAuth from "@hooks/useAuth";
import Landing from "@lib/feature/landing";
import serverSideTranslationProps from "@lib/util/serverSideTranslationProps";
import type { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const getStaticProps: GetStaticProps = async (context) => {
	return serverSideTranslationProps(context);
};

export default function HomePage() {
	const { replace } = useRouter();
	const { isUser } = useAuth();
	useEffect(() => {
		if (isUser) {
			replace("/map");
		}
	}, [replace, isUser]);

	if (isUser) {
		return null;
	}

	return <Landing />;
}
